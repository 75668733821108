<template>
  <div
    id="dropdown"
    class="absolute rounded-xl bg-white shadow-lg p-2 w-max z-20"
    :class="[positionUp ? 'bottom-full mb-2' : 'top-full mt-2']"
  >
    <slot></slot>
  </div>
</template>

<script>
// 此組件不常用到，請局部引入
export default {
  name: 'dropdown',
  data() {
    return {
      positionUp: false,
    }
  },
  mounted() {
    const dropdown = document.getElementById('dropdown')
    let rect = dropdown.getBoundingClientRect() // 元素的各項距離窗口的數值
    let height = rect.height // 取得元素高度
    let top = rect.top // 取的元素距離窗口頂端的高度
    let innerHeight = window.innerHeight // 取得視窗總高度
    // 若視窗總高度 - 元素距離窗口頂端的高度 < 元素高度 ， 代表元素會超出視窗範圍，需改變樣式
    if (innerHeight - top < height) this.positionUp = true
  },
}
</script>

<style scoped>
#dropdown {
  max-height: 300px;
  overflow: auto;
}
/* 在此組件中的專屬樣式*/
::v-deep .vs-button {
  width: 100%;
}

::v-deep .vs-button__content {
  justify-content: flex-start;
}
</style>
